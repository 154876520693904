import React from 'react'

const WorkIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'>
      <g data-name='Group 29'>
        <g data-name='Group 17' transform='translate(-172 -2403)'>
          <circle
            data-name='Ellipse 12'
            cx='60'
            cy='60'
            r='60'
            transform='translate(172 2403)'
            fill='#0d47a1'
          />
          <circle
            data-name='Ellipse 12'
            cx='50'
            cy='50'
            r='50'
            transform='translate(182 2414)'
            fill='#f2b705'
          />
          <circle
            data-name='Ellipse 12'
            cx='40'
            cy='40'
            r='40'
            transform='translate(192 2424)'
            fill='#0d47a1'
          />
          <circle
            data-name='Ellipse 12'
            cx='30'
            cy='30'
            r='30'
            transform='translate(201 2433)'
            fill='#f2b705'
          />
        </g>
        <path
          data-name='Polygon 3'
          d='m49.171 49.218 28.786-2.576L65.7 72.924Z'
          fill='#fff'
        />
        <path
          data-name='Rectangle 29'
          fill='#fff'
          d='m55.362 56 5.88 8.09-48.54 35.28-5.88-8.09z'
        />
      </g>
    </svg>
  )
}

export default WorkIcon
