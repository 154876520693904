import React from 'react'

const AboutIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 138.5 122.164'>
      <g data-name='Group 39' transform='translate(-286.5 -1241.5)'>
        <circle
          data-name='Ellipse 23'
          cx='56.821'
          cy='56.821'
          r='56.821'
          transform='translate(311.359 1250.023)'
          fill='#f2b705'
        />
        <path
          data-name='Path 43'
          d='M312.213 1241.5h94.282l-25.713 77.139H286.5Z'
          fill='#0d47a1'
        />
      </g>
    </svg>
  )
}

export default AboutIcon
