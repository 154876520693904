import React from 'react'

const MobileAppIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 117.848 99.493'>
      <g data-name='Group 28'>
        <path
          data-name='Rectangle 7'
          fill='#f2b705'
          d='m49.426 7.498 42.426 42.426-42.426 42.427L7 49.924z'
        />
        <path
          data-name='Rectangle 7'
          fill='#5996f8'
          d='m68.35 0 49.497 49.497L68.35 98.995 18.853 49.497z'
        />
        <g
          data-name='Rectangle 7'
          fill='none'
          stroke='#f2b705'
          strokeWidth='10'
        >
          <path
            stroke='none'
            d='m49.498.498 49.497 49.497-49.497 49.498L.001 49.995z'
          />
          <path d='m49.498 7.57 42.426 42.425-42.426 42.427L7.072 49.995z' />
        </g>
      </g>
    </svg>
  )
}

export default MobileAppIcon
