import React from "react";
import { Link } from "gatsby";
const styles = require("./styles.module.scss");

const HomeHero = () => {
  return (
    <section className={styles.homeHero}>
      <div className={styles.container}>
        <div className={styles.heroInner}>
          <h1 className={styles.heroTitle}>
            Design. <span className={styles.highlight}>Develop.</span> Evolve.
          </h1>
          <p>
            We craft elegant, lightning-fast websites and mobile apps tailored
            to drive growth in niche markets.
          </p>
          <p>
            Whether you're innovating with AI, scaling a SaaS platform,
            enhancing your e-commerce experience, or amplifying your marketing
            strategy, our design and development expertise ensures your digital
            presence is powerful and effective.
          </p>
          <div className={styles.cta}>
            <Link to='/contact' className={styles.button}>
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
