import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import WorkIcon from '../svg/Work'
const styles = require('./styles.module.scss')

const Portfolio = () => {
  return (
    <section id='work' className={styles.portfolio}>
      <div className={styles.portfolioInner}>
        <div className={styles.intro}>
          <span className={styles.iconBox}>
            <WorkIcon />
          </span>
          <h2>Our Work</h2>
          <p>
            - A curation of recent websites and mobile apps built with
            state-of-the-art tech stacks and industry best practices.
          </p>
        </div>

        <ul className={styles.projects}>
          <li className={styles.project}>
            <a
              href='https://kindle.ng/'
              target='_blank'
              className={styles.projectLink}
            >
              <figure>
                <StaticImage
                  src={'../../images/utility-payment-app.png'}
                  alt='kindleng payment app'
                />
                <figcaption className={styles.projectTitle}>
                  KindleNG{' '}
                  <span className={styles.projectType}>
                    | Web and mobile app
                  </span>
                </figcaption>
              </figure>
            </a>
          </li>

          <li className={styles.project}>
            <a
              href='https://kindle.ng/'
              target='_blank'
              className={styles.projectLink}
            >
              <figure>
                <StaticImage
                  src={'../../images/book-search-app.png'}
                  alt='book search application'
                />
                <figcaption className={styles.projectTitle}>
                  Bookfindr{' '}
                  <span className={styles.projectType}>| Web application</span>
                </figcaption>
              </figure>
            </a>
          </li>

          <li className={styles.project}>
            <figure>
              <StaticImage
                src={'../../images/safr-web-app.png'}
                alt='book search application'
              />
              <figcaption className={styles.projectTitle}>
                Safr{' '}
                <span className={styles.projectType}>
                  | Web app(coming soon)
                </span>
              </figcaption>
            </figure>
          </li>

          <li className={styles.project}>
            <a
              href='https://krebe.dev/'
              target='_blank'
              className={styles.projectLink}
            >
              <figure>
                <StaticImage
                  src={'../../images/portfolio-website.png'}
                  alt='portfolio-website'
                />
                <figcaption className={styles.projectTitle}>
                  KrebeDev{' '}
                  <span className={styles.projectType}>
                    | Portfolio website
                  </span>
                </figcaption>
              </figure>
            </a>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Portfolio
