import React from 'react'

const WebDesignIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 80'>
      <g data-name='Group 27' transform='translate(-870 -1806)'>
        <circle
          data-name='Ellipse 2'
          cx='40'
          cy='40'
          r='40'
          transform='translate(870 1806)'
          fill='#5996f8'
        />
        <circle
          data-name='Ellipse 2'
          cx='40'
          cy='40'
          r='40'
          transform='translate(910 1806)'
          fill='#f2b705'
        />
        <circle
          data-name='Ellipse 2'
          cx='20'
          cy='20'
          r='20'
          transform='translate(930 1828)'
          fill='#5996f8'
        />
      </g>
    </svg>
  )
}

export default WebDesignIcon
