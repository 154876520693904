import React from 'react'

const BrandingIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 112 72'>
      <g data-name='Group 26' transform='translate(-249 -1812)'>
        <path data-name='Polygon 2' d='m322 1814 39 65h-78Z' fill='#f2b705' />
        <circle
          data-name='Ellipse 3'
          cx='36'
          cy='36'
          r='36'
          transform='translate(249 1812)'
          fill='#5996f8'
        />
        <circle
          data-name='Ellipse 4'
          cx='20'
          cy='20'
          r='20'
          transform='translate(265 1828)'
          fill='#f2b705'
        />
      </g>
    </svg>
  )
}

export default BrandingIcon
