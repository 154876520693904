import React from 'react'
import { Link } from 'gatsby'
const styles = require('./styles.module.scss')

const CTA = () => {
  return (
    <section className={styles.cta}>
      <div className={styles.ctaInner}>
        <h2>Ready to start a project?</h2>
        <p>
          The best time to begin is now! <br /> Let's translate your idea into a
          stunning website or mobile app that generates optimal ROI.
        </p>

        <div className={styles.btnBox}>
          <Link to='/contact' className={styles.ctaButton}>
            Contact us
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CTA
