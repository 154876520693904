import React from "react";
import BrandingIcon from "../svg/Branding";
import AboutIcon from "./../svg/About";
import WebDesignIcon from "./../svg/WebDesign";
import MobileAppIcon from "./../svg/MobileApp";
const styles = require("./styles.module.scss");

const About = () => {
  return (
    <section className={styles.about} id='about'>
      <div className={styles.aboutInner}>
        <div className={styles.overview}>
          <div className={styles.iconBox}>
            <AboutIcon />
          </div>
          <h2>Who we are</h2>
          <p>
            We are a tight-knit team of designers, developers, and product
            managers that serve a global clientele in various industries. Our
            sole craft is creating impeccable digital products. This service
            spectrum includes branding and creative design, web and mobile
            applications development, and anything in-between.
          </p>
        </div>
        <dl className={styles.services}>
          <div className={styles.service}>
            <dt>
              <span className={styles.serviceIcon}>
                <WebDesignIcon />
              </span>
              Website Design &amp; Development
            </dt>
            <dd>
              We develop bespoke, modern, beautiful, and responsive websites,
              e-commerce solutions, and web applications.
            </dd>
          </div>

          <div className={`${styles.service} ${styles.bordered}`}>
            <dt>
              <span className={styles.serviceIcon}>
                <MobileAppIcon />
              </span>
              Mobile App Development
            </dt>
            <dd>
              We build innovative mobile apps for Android and IOS devices that
              promotes user engagement.
            </dd>
          </div>
          <div className={styles.service}>
            <dt>
              <span className={styles.serviceIcon}>
                <BrandingIcon />
              </span>
              Branding &amp; Creative Design
            </dt>
            <dd>
              We create and redefine brand positioning, strategy, identity, and
              voice to tell your brand story succinctly.
            </dd>
          </div>
        </dl>
      </div>
    </section>
  );
};

export default About;
