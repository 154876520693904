import * as React from "react";
import HomeHero from "../components/HomeHero";
import Layout from "./../components/Layout/index";
import { Helmet } from "react-helmet";
import About from "../components/About";
import Portfolio from "../components/Portfolio";
import CTA from "./../components/CTA/index";
import { graphql } from "gatsby";

const IndexPage = ({ data }: any) => {
  return (
    <>
      <Helmet
        title={`Best Web & Mobile App Developers in Abuja | ${data.site.siteMetadata.title}`}
      />
      <Layout>
        <HomeHero />
        <About />
        <Portfolio />
        <CTA />
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
